<template> 
<div v-if="perms.includes('Utilisateurs') || perms.includes('Admin')">
    <div v-if="loading">
      <div id="loading-bg">
      <div class="loading">
        <div class="effect-1 effects"></div>
        <div class="effect-2 effects"></div>
        <div class="effect-3 effects"></div>
      </div>
    </div>
  </div>
  <b-card v-else >
 <!-- <user-list-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
    />
     <user-list-update
      :is-update-user-sidebar-active.sync="isUpdateUserSidebarActive"
      
    /> -->
   
       <div  class="custom-search">
        <b-row>
          
          <b-col  md="3"  > 
            <b-button
              variant="primary"
              v-b-toggle href="#add-user-sidebar" @click.prevent
              
              
            >
              <span class="text-nowrap">Nouveau Utilisateur</span>
            </b-button>
          </b-col>
          <b-col 
            md="9"
          >
          <b-form-input
            v-model="searchTerm"
            placeholder="Chercher"
            type="text"
            class="d-inline-block mr-1"
          />
          </b-col>
        
          
        </b-row>
        </div>
        <br/>

      <!-- </div> -->

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="users"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Name -->
        <div
          v-if="props.column.field === 'name'"
          class="text-nowrap"
        >
          <span class="text-nowrap">{{ props.row.name }}</span>
        </div>

        <!-- Column: Status -->
        <!-- <span v-else-if="props.column.field === 'role'">
          <b-badge :variant="statusVariant(props.row.role)">
            {{ props.row.role }}
          </b-badge>
        </span> -->
        
        <span v-else-if="props.column.field === 'archived'">
          <template v-if="UserRole.role==='SuperAdmin' && UserRole.id===1">
            <b-form-checkbox
          v-model="props.row.archived"
          class="custom-control-warning"
          name="check-button"
          switch
          @change="UserArchived(props.row)"
          value="1"
          unchecked-value="0"
        />
          </template>
        </span>
      
        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
            <template v-if="UserRole.role==='SuperAdmin' && UserRole.id===1">  
              <span>
                <b-row>             
                    <b-button style="margin-right: 4px; margin-left: 5px;"
                        variant="gradient-success"
                        class="btn-icon"
                        v-b-toggle href="#update-user-sidebar" @click.prevent @click="showUpdate(props.row)"
                        >
                        <feather-icon icon="Edit2Icon" />
                        
                    </b-button>
              
                    <b-button
                        variant="gradient-info"
                        class="btn-icon"
                        @click="$router.push({ name: 'details-user', params: { id: props.row.id }})"
                        >
                        <feather-icon icon="EyeIcon" />
                        
                    </b-button>
                    </b-row>
                  </span>
              </template>
              
 
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['8','10','20']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap "> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>

  </b-card>
  <b-sidebar 
    id="add-user-sidebar"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
           Nouveau Utilisateur
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- Body -->
      <validation-observer
            ref="formdata"
            #default="{invalid}"
          >
      <b-form id="formdata"
        class="p-2"
        @submit.prevent
      >

        <b-form-group
          label="Nom"
          label-for="nom"
         
        >
        <validation-provider
            #default="{ errors }"
            name="Nom"
            rules="required"
          >
          <b-form-input
            id="nom"
            v-model="form.name"
            trim
            :state="errors.length > 0 ? false:null"
          />
          <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        
        <!-- Product Name -->
        <b-form-group
          label="Prénom"
          label-for="prenom"
        >
         <validation-provider
            #default="{ errors }"
            name="Prénom"
            rules="required"
          >
          <b-form-input
            id="prenom"
            v-model="form.prenom"
            trim
            :state="errors.length > 0 ? false:null"
          />
           <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
         <b-form-group
              label="Email"
              label-for="email"
            >
             <validation-provider
            #default="{ errors }"
            name="Email"
            rules="required|email"
          >
              <b-form-input
                id="email"
                v-model="form.email"
                trim
                :state="errors.length > 0 ? false:null"
                type="email"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Téléphone"
              label-for="phone"
            >
            <validation-provider
            #default="{ errors }"
            name="Téléphone"
            rules="required"
          >
              <b-form-input
                id="phone"
                v-model="form.phone"
                trim
                :state="errors.length > 0 ? false:null"
                type="number"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-group>
             <b-form-group
              label="Mot De Passe"
              label-for="password"
            >
            <validation-provider
            #default="{ errors }"
            name="mot de passe"
            rules="required"
          >
              <b-form-input
                id="password"
                v-model="form.password"
                trim
                :state="errors.length > 0 ? false:null"
                type="password"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-group>

             <b-form-group>
      <template slot="label">
        <b>Rôle :</b><br>
        <b-form-checkbox
          v-model="allSelected"
          aria-describedby="flavours"
          aria-controls="flavours"
          @change="toggleAll"
        > Admin
          <!-- {{ allSelected ? 'Admin' : '' }} -->
        </b-form-checkbox>
      </template>

      <b-form-checkbox-group
        id="flavors"
        v-model="selected"
        :options="flavours"
        name="flavors"
        class="demo-inline-spacing"
        aria-label="Permissions"
        stacked
        @change="Unselect"
      ></b-form-checkbox-group>
    </b-form-group>

            <!-- {{userIds}} -->
           <!--<b-form-group label="Rôle">
           <b-form-checkbox  aria-describedby="optionsPerms"
          aria-controls="optionsPerms"
            class="demo-inline-spacing" @click="toggleAll" v-model="allSelected">
                  {{ allSelected ? 'Un-select All' : 'Select All' }}
                </b-form-checkbox>
              <b-form-checkbox-group
                v-model="selected"
                :options="optionsPerms"
                class="demo-inline-spacing"
                name="checkbox-validation"
              >
               
                :state="state" -->
                <!-- <b-form-checkbox value="Facturation Fournisseurs">
                  Facturation Fournisseurs
                </b-form-checkbox>
                <b-form-checkbox value="Facturation Clients">
                  Facturation Clients
                </b-form-checkbox>
                <b-form-checkbox value="Produits & Stock">
                  Produits & Stock
                </b-form-checkbox>
                <b-form-checkbox value="Utilisateurs">
                  Utilisateurs
                </b-form-checkbox>
                <b-form-checkbox value="Dashbaord">
                  Dashbaord
                </b-form-checkbox>
                <b-form-checkbox value="Catégories">
                  Catégories
                </b-form-checkbox>
                <b-form-checkbox value="Paramètrage">
                  Paramètrage
                </b-form-checkbox> -->
                      <!-- <b-form-invalid-feedback :state="state">
                        Il Faut choisir un rôle
                      </b-form-invalid-feedback>
                      <b-form-valid-feedback :state="state">
                       
                      </b-form-valid-feedback> 
              </b-form-checkbox-group>
            </b-form-group>-->
       
            <!-- <b-form-group
              label="Rôle"
              label-for="role"
            >
          <v-select
            v-model="form.role"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="title"
            :options="optionRoles"
          />
       
            </b-form-group> -->
           
                    <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
            :disabled="invalid"
            @click="NewUser()"
          >
            Ajouter
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            @click="hide"
          >
            Annuler
          </b-button>
        </div>
      </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
   <b-sidebar 
    id="update-user-sidebar"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
           Modifier Utilisateur
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- Body -->
      <b-form id="formdata"
        class="p-2"
        @submit.prevent
      >
  
        <b-form-group
          label="Nom"
          label-for="nom"
        >
          <b-form-input
            id="nom"
            v-model="form2.name"
            trim
          />
        </b-form-group>
        
        <!-- Product Name -->
        <b-form-group
          label="Prénom"
          label-for="prenom"
        >
          <b-form-input
            id="prenom"
            v-model="form2.prenom"
            trim
          />
        </b-form-group>
         <b-form-group
              label="Email"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="form2.email"
                trim
                type="email"
              />
            </b-form-group>
            <b-form-group
              label="Téléphone"
              label-for="phone"
            >
              <b-form-input
                id="phone"
                v-model="form2.phone"
                trim
                type="number"
              />
            </b-form-group>
            <!-- <b-form-group
              label="Rôle"
              label-for="role"
            >
             <select class="form-control" v-model="form2.role">
               <option value="Admin">Admin</option>
               <option value="Editeur">Editeur</option>
             </select>
            </b-form-group> -->
            <b-form-group
              label="Mot De Passe"
              label-for="password"
            >
              <b-form-input
                id="password"
                v-model="form2.password"
                trim
                placeholder="*********"
                type="password"
              />
            </b-form-group>
            <b-form-group  label="Permissions">
                <div v-for="(perm,index) in permissionsData" :key="index">
                <b-form-checkbox  type="checkbox" :placeholder="perm.name"  :value="perm.check"
                :unchecked-value="!perm.check" v-if="perm.check==1" checked v-model="perm.check" :id="perm.name">
                {{perm.name}}
              </b-form-checkbox>
                
                <b-form-checkbox  type="checkbox" :placeholder="perm.name" v-if="perm.check==0"  v-model="perm.check" :id="perm.name">
                  {{perm.name}}
                </b-form-checkbox>
                 <!-- <label :for="perm.name">{{perm.name}}</label> -->
                </div>
            </b-form-group>

                    <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
            @click="UpdateUser()"
          >
            Modifier
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            @click="hide"
          >
            Annuler
          </b-button>
        </div>
      </b-form>
    </template>
  </b-sidebar>
</div>
<div v-else>
    <not-authoriz></not-authoriz>
</div>
</template>

<script>
import config from '@/config'
import {
 BButton,BCard, BRow, BCol, BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown,
  BDropdownItem,VBToggle,BCollapse,BSidebar,BForm,BFormSpinbutton,BFormCheckbox,BFormCheckboxGroup,BFormInvalidFeedback, BFormValidFeedback
} from 'bootstrap-vue' 
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import UserListAddNew from './UserListAddNew.vue'
import UserListUpdate from './UserListUpdate.vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum, email } from '@validations'
import NotAuthoriz from '@/views/admin/NotAuthoriz.vue'

export default {
  components: {
    UserListAddNew,
    UserListUpdate,
    BCard,VBToggle,BCollapse,BSidebar,BForm,BFormSpinbutton,BFormCheckbox,
    VueGoodTable,
    BRow, BCol,
    BButton,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    vSelect,
     ValidationProvider,
    ValidationObserver,NotAuthoriz,BFormCheckboxGroup,
    BFormInvalidFeedback, BFormValidFeedback

    
  },
        directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data() {
    return {
       required,
      alphaNum,
      email,
      perms:[],
      UserRole:{},
      flavours:["Facturation Fournisseurs", "Facturation Clients", "Produits & Stock","Utilisateurs","Dashbaord","Catégories","Paramètrage"],
      selected: [],
      allSelected: false,
      userIds: [],
      loading : false,
      pageLength: 8,
      dir: false,
      isAddNewUserSidebarActive:false,
      isUpdateUserSidebarActive:false,
      columns: [
        {
          label: 'Nom',
          field: 'name',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher Name',
          },
        },
         {
          label: 'Prénom',
          field: 'prenom',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher Name',
          },
        },
        {
          label: 'Email',
          field: 'email',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher Email',
          },
        },
        {
          label: 'Téléphone',
          field: 'phone',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher phone',
          },
        },
        {
          label: 'Archivé',
          field: 'archived',
        },
        {
          label: 'Action',
          field: 'action',
        },
      
        // {
        //   label: 'Rôle',
        //   field: 'role',
        //   filterOptions: {
        //     enabled: true,
        //     placeholder: 'Search role',
        //   },
        // },
       
        
      ],
      optionRoles: [{ title: 'Admin' }, { title: 'Editeur' }],
      users: [],
      searchTerm: '',
      checked:false,
      UserID:{},
       form :new Form({    
          id:0,
          name:'',
          prenom:'',
          email:'',
          phone:'',
          role:'',
          password:'',
          }),
          form2 :new Form({    
          id:0,
          name:'',
          prenom:'',
          email:'',
          phone:'',
          role:'',
          password:'',
          }),
          permissionsData:[]
        }
  },
  methods:{
   toggleAll(checked) {
        // this.selected = checked ? this.flavours.slice() : []
        // this.selected.push("Admin")
        if(!checked){
          this.selected=[]
        }
        else{
          this.selected=[]
          this.selected=this.flavours.slice()
          this.selected.push("Admin")
        }
      },
      Unselect(checked){
        if(checked){
            this.allSelected = false;
             this.selected =  this.selected.filter(function(f) { return f !== 'Admin' })
        }
            
      },
    getUsers(){
      this.loading = true
       this.$http.get(`${config.API_BASE_URL}/api/auth/users`)
      .then(res => { this.users=res.data,this.loading = false, console.clear() })
    },
    NewUser(){

              let data= new FormData;
          data.append('UserID', this.UserID.id);
          data.append('name', this.form.name);
          data.append('prenom', this.form.prenom);
          data.append('email', this.form.email);
          data.append('phone', this.form.phone);
          data.append('password', this.form.password);
          for(let i=0; i<this.selected.length;i++){
                    data.append('permissions[]',this.selected[i]);
                }
          // console.log(Object.fromEntries(data))
        if(this.selected.length==0){
            this.$swal.fire({
                icon: 'error',
                title: 'No Rôle',
                text: 'Merci de choisir Une permission'
                })
        }else{
            this.$http.post(`${config.API_BASE_URL}/api/auth/createusers`,data) .then(res => {   
            if(res.data=='success'){
               document.getElementById("formdata").reset();
              this.getUsers();
                this.$swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Utilisateur est bien sauvegardeé',
                showConfirmButton: false,
                timer: 1000 })
                }else if(res.data=='email exist'){
                  this.$swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Un utilisateur avec cet mail est déjà existe'
                })
                }     
        })
        .catch(() => {
            this.$swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong! Pleaz try again'
                })
        })
        }
        
    },
    showUpdate(com){
      this.GetPermsUser(com.id)
      this.form2.reset();
      this.form2.fill(com);
      this.form2.password='';
    },
    GetPermsUser(id){
     this.$http.get(`${config.API_BASE_URL}/api/auth/GetPermsCheck/${id}`)
     .then(res =>{this.permissionsData=res.data})

    },
    UpdateUser(){
      console.log(this.permissionsData)
        let data= new FormData;
          data.append('UserID', this.UserID.id);
          data.append('name', this.form2.name);
          data.append('prenom', this.form2.prenom);
          data.append('email', this.form2.email);
          data.append('phone', this.form2.phone);
          data.append('role', this.form2.role);
          data.append('password', this.form2.password);
          data.append('perms', JSON.stringify(this.permissionsData));
          // console.log(Object.fromEntries(data))
        this.$http.post(`${config.API_BASE_URL}/api/auth/updateusers/${this.form2.id}`,data) .then(res => {   
            if(res.data=='success'){
              this.getUsers();
                this.$swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Utilisateur est bien modifié',
                showConfirmButton: false,
                timer: 1000 })
                }      
        })
        .catch(() => {
            this.$swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong! Pleaz try again'
                })
        })
    },
    DetailsUser(id){
        console.log(id);
      },
    UserArchived(row){
      let data= new FormData;
      data.append('UserID', this.UserID.id);
      data.append('archived', row.archived)
      if( row.archived==1){
               this.$swal.fire({
                        text: "Vous voulez archiver cet utilisateur ?",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Oui, supprimer!'
                      }).then((result) => {
                        if (result.value) {
                             this.$http.post(`${config.API_BASE_URL}/api/auth/deleteusers/${row.id}`,data)
                              .then(res=> {
                                    if(res.data=='Success'){
                                    this.$swal.fire({
                                    position: 'top-end',
                                    icon: 'success',
                                    title: 'Utilisateur est bien archivé',
                                    showConfirmButton: false,
                                    timer: 1500  })   
                                    this.getUsers(); 
                                  }   
                              }).catch(() => {
                                  this.$swal.fire({
                                        icon: 'error',
                                        title: 'Oops...',
                                        text: 'Something went wrong! Pleaz try again'
                                      });
                              })
                          }  })
      }else{
               this.$swal.fire({
                        text: "Vous voulez désarchiver cet utilisateur ?",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Oui, désarchiver!'
                      }).then((result) => {
                        if (result.value) {
                             this.$http.post(`${config.API_BASE_URL}/api/auth/deleteusers/${row.id}`,data)
                              .then(res=> {
                                    if(res.data=='Success'){
                                    this.$swal.fire({
                                    position: 'top-end',
                                    icon: 'success',
                                    title: 'Utilisateur est bien désarchivé',
                                    showConfirmButton: false,
                                    timer: 1500  })   
                                    this.getUsers(); 
                                  }   
                              }).catch(() => {
                                  this.$swal.fire({
                                        icon: 'error',
                                        title: 'Oops...',
                                        text: 'Something went wrong! Pleaz try again'
                                      });
                              })
                          }  })
      }

      }
  },
  computed: {
     state() {
      return this.userIds.length >= 1
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Admin      : 'light-info',
        admin      : 'light-info',
        Editeur : 'light-success',
        Autre     : 'light-danger',
        Client     : 'light-warning',
        // Applied      : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
      this.perms=JSON.parse(localStorage.getItem('access'));
      this.UserRole=JSON.parse(localStorage.getItem('userData'));
      this.getUsers();
      this.UserID=JSON.parse(localStorage.getItem('userData'))
      // console.log(this.UserID)
    // this.$http.get(`${config.API_BASE_URL}/api/auth/users`)
    //   .then(res => { this.users=res.data })
      
  }
}
</script>
<style scoped>
a.btn.btn-icon.btn-gradient-success.collapsed {

    margin-right: 6px;
    margin-left: 8px;
}
</style>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>

